export const PricesData = [
  {
    title: "Fiat",
    price: "$0",
  },
  {
    title: "Crypto",
    price: "$0",
  },
  {
    title: "$DOG earned",
    price: "0",
  },
];

export const streetDogFeeding = [
  {
    title: "Amount Donated",
    price: "$0",
  },
  {
    title: "$DOG earned",
    price: "0",
  },
];
