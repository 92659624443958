export const proNavData = [
  { title: "All" },
  {
    title: "New",
  },
  {
    title: "Featured",
  },
  {
    title: "Popular",
  },
  {
    title: "Recently Viewed",
  },
  {
    title: "Rated",
  },
  {
    title: "Price",
  },
];
